const datalayer = typeof window !== "undefined" && window.dataLayer;
const noop = () => {};

const useGTM = () => {
  const push = (datalayer && datalayer.push) || noop;

  // Failure to load the GTM script can throw unhandled errors,
  // since the datalayer object will still be present.
  //
  // I, um, might have been blocking tracking scripts... and found out <_<
  const safePush = props => {
    try {
      push(props);
    } catch (error) {
      console.error("GTM Error", error.message);
    }
  };

  return { push: safePush };
};

export default useGTM;
