import React, { useState } from "react";
import ReactPlayer from "react-player";

import useGTM from "../hooks/useGTM";

import VideoList from "../components/VideoList";
import playIcon from "../images/icons/icon_play.svg";

import posterImg from "../images/posters/poster-intro.jpg";

import { css } from "@emotion/core";
import styled from "@emotion/styled";
import tw from "tailwind.macro";

import { AspectRatioBox } from "../styles/aspect-ratio.styles.js";
import { depth2 } from "../styles/utilities.styles.js";

const styles = {
  wrap: tw`flex flex-col lg:flex-row`,
  player: tw`pin-l pin-t absolute cursor-pointer`,
  playerBox: tw`w-full lg:w-2/3`,
  playList: tw`"relative w-full lg:w-1/3`,
  hitbox: css`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    -webkit-appearance: none;
    border: 0;
    z-index: 11;
  `,
  playIcon: css`
    ${tw`w-10 md:w-16 lg:w-24 cursor-pointer`};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 10;
  `,
  poster: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  `,
  timecode: css`
    ${tw`font-semibold`}
    position: absolute;
    left: 20px;
    bottom: 20px;
    z-index: 10;
  `,
};

const S = {
  AspectRatioBox,
  Timecode: styled.span`
    ${styles.timecode};
    color: ${({ dark }) => (dark ? "black" : "white")};
  `,
};

const PreviewPlayer = ({ showPlaylist, videoUrl, poster, dark }) => {
  const [duration, setDuration] = useState();
  const [playing, setPlaying] = useState(false);
  const [url, setUrl] = useState(videoUrl);
  const { push } = useGTM();

  const VideoPlayer = () => (
    <S.AspectRatioBox
      ratio="16x9"
      css={depth2}
      data-tracking="course-preview-player"
    >
      {!playing && (
        <>
          <button css={styles.hitbox} onClick={() => setPlaying(true)}></button>
          <img src={playIcon} alt="Play Video" css={styles.playIcon} />
          {poster && <img src={poster} alt="" css={styles.poster} />}
          {duration && <S.Timecode dark={dark}>{duration}</S.Timecode>}
        </>
      )}
      <ReactPlayer
        url={url}
        controls={true}
        playing={playing}
        width="100%"
        height="100%"
        css={styles.player}
        onPlay={() => {
          push({ event: "course-preview-play" });
          setPlaying(true);
        }}
        onPause={() => {
          push({ event: "course-preview-pause" });
        }}
        onDuration={(d) => {
          const seconds = d >> 0; // bitwise cast to int to drop fractional seconds.
          const mm = Math.floor(seconds / 60) + "";
          const ss = seconds - mm * 60 + "";
          setDuration(`${mm}:${ss.padStart(2, "0")}`);
        }}
      />
    </S.AspectRatioBox>
  );

  if (!showPlaylist) {
    return <VideoPlayer />;
  }

  return (
    <div css={styles.wrap}>
      <div css={styles.playerBox}>
        <VideoPlayer />
      </div>
      <div css={styles.playList}>
        <VideoList
          activeUrl={url}
          onSelectVideo={(video_url) => {
            setUrl(video_url);
            setPlaying(true);
          }}
        />
      </div>
    </div>
  );
};

PreviewPlayer.defaultProps = {
  videoUrl: "https://player.vimeo.com/video/415715387",
  showPlaylist: false,
  poster: posterImg,
  dark: false,
};

export default PreviewPlayer;
