import React from "react";
// import { Link } from "gatsby";

import tw from "tailwind.macro";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

import {
  AspectRatioBox,
  aspectRatioInner
} from "../styles/aspect-ratio.styles.js";

import { depth1, noSelect } from "../styles/utilities.styles.js";

const styles = {
  wrap: tw`flex mb-3 cursor-pointer`,
  thumb: tw`w-2/5 flex-no-shrink mr-3`,
  thumbBox: css`
    ${depth1};
    ${noSelect};
  `,
  poster: css`
    ${aspectRatioInner};
    ${tw`bg-white bg-cover flex items-center justify-center`}
  `,
  active: tw`text-base bg-watermelon-red text-white border-transparent`,
  standard: tw`text-sm border-watermelon-red text-black`,
  big: css`
    width: 50px;
    height: 50px;
  `,
  small: css`
    width: 35px;
    height: 35px;
  `,
  labelNo: tw`rounded-full border-2 flex items-center justify-center font-bold`,
  title: tw`text-sm font-bold inline mr-1 `,
  text: tw`text-sm inline leading-tight`
};

const S = {
  AspectRatioBox,
  Poster: styled.div`
    ${tw`absolute bg-cover pin`};
    ${({ poster }) => `background-image: url(${poster})`};
  `,
  Label: styled.div`
    ${styles.labelNo};
    line-height: 0;
    ${({ active }) => (active ? styles.active : styles.standard)}
    ${({ active }) => (active ? styles.big : styles.small)}
  `
};

const VideoListItem = ({
  active,
  description,
  idx,
  onClick,
  poster: posterImg,
  title
}) => (
  <figure css={styles.wrap} onClick={onClick}>
    <div css={styles.thumb}>
      <AspectRatioBox ratio="16x9" css={styles.thumbBox}>
        <div css={styles.poster}>
          {idx === 0 && <S.Poster poster={posterImg} />}
          {idx && <S.Label active={active}>{idx}</S.Label>}
        </div>
      </AspectRatioBox>
    </div>
    <div>
      <h2 css={styles.title}>
        {idx ? `${idx}: ` : ``}
        {title}
      </h2>
      <p css={styles.text}>{description}</p>
    </div>
  </figure>
);

export default VideoListItem;
