import React from "react";

import VideoListItem from "../components/VideoListItem";

import tw from "tailwind.macro";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

import { shade1 } from "../styles/utilities.styles.js";

import intro from "../images/thumbnails/intro.png";
import lesson_1 from "../images/thumbnails/lesson_1.png";
import lesson_2 from "../images/thumbnails/lesson_2.png";
import lesson_3 from "../images/thumbnails/lesson_3.png";
import lesson_4 from "../images/thumbnails/lesson_4.png";
import lesson_5 from "../images/thumbnails/lesson_5.png";
import lesson_6 from "../images/thumbnails/lesson_6.png";
import lesson_7 from "../images/thumbnails/lesson_7.png";
import lesson_8 from "../images/thumbnails/lesson_8.png";
import lesson_9 from "../images/thumbnails/lesson_9.png";
import lesson_10 from "../images/thumbnails/lesson_10.png";
import lesson_11 from "../images/thumbnails/lesson_11.png";

// import intro_poster from "../images/posters/poster-intro.jpg";

const styles = {
  wrap: tw`flex flex-col relative h-full`,
  title: tw`mb-6 text-xl leading-normal font-bold text-center`,
  videoList: tw`lg:absolute lg:pin overflow-hidden py-5 pb-16 px-2 lg:px-5 overflow-scroll`,
  fadeOutOverlay: css`
    ${tw`absolute pin-b pin-l pin-r h-16 z-10`};
    ${shade1};
  `,
};

const S = {
  CoursePart: styled.div`
    ${({ idx }) => idx === 0 && tw`pb-6`}
    ${({ idx }) => idx > 0 && tw`border-t py-6`}
  `,
};

const VideoList = ({ parts, activeUrl, onSelectVideo }) => (
  <div css={styles.wrap}>
    <div css={styles.fadeOutOverlay} />
    <div css={styles.videoList}>
      {parts.map(({ title, videos }, idx) => (
        <S.CoursePart as="section" idx={idx} key={`part-${idx}`}>
          {title && <h2 css={styles.title}>{title}</h2>}
          {videos.map(({ label, video_url, ...video }, idx) => (
            <VideoListItem
              {...video}
              key={`v-${label}`}
              idx={label}
              active={video_url === activeUrl}
              onClick={() => onSelectVideo(video_url)}
            />
          ))}
        </S.CoursePart>
      ))}
    </div>
  </div>
);

const videos = [
  {
    label: 0,
    title: "Overview",
    description: "An introduction from the Head of TED, Chris Anderson.",
    video_url: "https://player.vimeo.com/video/415715387",
    poster: intro,
  },
  {
    label: 1,
    title: "What are ideas?",
    description:
      "You'll learn how to spot the differences between bad ideas, good ideas and great ideas.",
    video_url: "https://player.vimeo.com/video/415658931",
    poster: lesson_1,
  },
  {
    label: 2,
    title: "What are your ideas?",
    description:
      "You'll engage in a rigorous brainstorm and develop a list of ideas for your talk.",
    video_url: "https://player.vimeo.com/video/415659650",
    poster: lesson_2,
  },
  {
    label: 3,
    title: "What's your through-line?",
    description: "You'll decide which ideas to keep and which ideas to cut.",
    video_url: "https://player.vimeo.com/video/415660602",
    poster: lesson_3,
  },
  {
    label: 4,
    title: "Craft your talk plan",
    description: "You'll learn when to script your talk and when to improvise.",
    video_url: "https://player.vimeo.com/video/415669743",
    poster: lesson_4,
  },
  {
    label: 5,
    title: "Voice and presence",
    description:
      "Learn how to use your voice and body language to enhance the delivery of your talk.",
    video_url: "https://player.vimeo.com/video/415670163",
    poster: lesson_5,
  },
  {
    label: 6,
    title: "Connection",
    description:
      "You'll learn how to connect with audience members (in person and online) as you deliver your talk.",
    video_url: "https://player.vimeo.com/video/415671541",
    poster: lesson_6,
  },
  {
    label: 7,
    title: "Storytelling",
    description:
      "Learn the ways the ancient art of storytelling can shape your delivery.",
    video_url: "https://player.vimeo.com/video/415672138",
    poster: lesson_7,
  },
  {
    label: 8,
    title: "Explanation",
    description: "You'll learn how to express complex ideas with clarity.",
    video_url: "https://player.vimeo.com/video/415708388",
    poster: lesson_8,
  },
  {
    label: 9,
    title: "Persuasion",
    description:
      "You'll learn how to convince others to genuinely consider your point of view.",
    video_url: "https://player.vimeo.com/video/415708843",
    poster: lesson_9,
  },
  {
    label: 10,
    title: "Revelation",
    description:
      "You'll learn how to reveal your ideas in exciting and engaging ways.",
    video_url: "https://player.vimeo.com/video/415709293",
    poster: lesson_10,
  },
  {
    label: 11,
    title: "How to think about visuals",
    description:
      "You'll learn how to (and how not to) use visuals to enhance your presentation.",
    video_url: "https://player.vimeo.com/video/415709941",
    poster: lesson_11,
  },
];

const parts = [
  {
    videos: videos.slice(0, 1),
  },
  {
    title: "Part 1: Identify your ideas",
    videos: videos.slice(1, 4),
  },
  {
    title: "Part 2: Plan your approach",
    videos: videos.slice(4, 6),
  },
  {
    title: "Part 3: Develop your talk",
    videos: videos.slice(6),
  },
];

VideoList.defaultProps = {
  parts,
  videos,
};

export default VideoList;
